var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contractManageDetail-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          { ref: "formPanel", on: { update: _vm.update } },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c("div", { staticClass: "section-wrapper" }, [
            _c("span", { staticClass: "title-text" }, [_vm._v("合同信息")]),
          ]),
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                [
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "合同名称",
                      text: _vm.detailItem.contractName,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "合同编号",
                      text: _vm.detailItem.contractNo,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "合同起止时间",
                      text: `${_vm.detailItem.startDate || ""} ~ ${
                        _vm.detailItem.endDate || ""
                      }`,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "创建人",
                      text: `${_vm.detailItem.inuser || ""}`,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "创建时间",
                      text: `${_vm.detailItem.intime || ""}`,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "section-wrapper" }, [
            _c("span", { staticClass: "tip-wrapper" }, [_vm._v("1")]),
            _c("span", { staticClass: "title-text" }, [_vm._v("承租信息")]),
          ]),
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                [
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "关联项目",
                      text: _vm.detailItem.communityName,
                    },
                  }),
                  _c("col2-item", { attrs: { span: 24, label: "关联房号" } }, [
                    _vm.houseList.length > 0
                      ? _c(
                          "div",
                          { staticClass: "list-wrapper" },
                          _vm._l(_vm.houseList, function (itemList, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "list-row" },
                              _vm._l(itemList, function (item, itemIndex) {
                                return _c(
                                  "div",
                                  {
                                    key: itemIndex,
                                    staticClass: "list-row-item",
                                  },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: item.houseName,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "list-row-text" },
                                          [_vm._v(_vm._s(item.houseName))]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ]),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "组织名称",
                      text: _vm.detailItem.orgName,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "联系人姓名",
                      text: _vm.detailItem.contactUser,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "联系电话",
                      text: _vm.detailItem.mobileNo,
                    },
                  }),
                  _vm.contractFileUrls.length > 0
                    ? _c(
                        "col2-item",
                        { attrs: { span: 24, label: "合同附件" } },
                        [
                          _c(
                            "div",
                            { staticClass: "list-wrapper" },
                            _vm._l(
                              _vm.contractFileUrls,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: item.urlPath,
                                    staticClass: "contract-file-wrapper",
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "contract-file-text" },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(item.name) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _c("v-button", {
                                      attrs: { type: "text", text: "下载附件" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadFile(index)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c("col2-item", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.detailItem.remark,
                        expression: "detailItem.remark",
                      },
                    ],
                    attrs: {
                      span: 24,
                      label: "合同备注",
                      text: _vm.detailItem.remark,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "section-wrapper" }, [
            _c("span", { staticClass: "tip-wrapper" }, [_vm._v("2")]),
            _c("span", { staticClass: "title-text" }, [_vm._v("收费信息")]),
          ]),
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "押金设置" } },
                [
                  !_vm.depositInfo
                    ? _c("col2-item", {
                        attrs: { span: 24, label: "押金设置", text: "无" },
                      })
                    : _c(
                        "div",
                        [
                          _c("col2-item", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.depositInfo.depositNo,
                                expression: "depositInfo.depositNo",
                              },
                            ],
                            attrs: {
                              span: 12,
                              label: "押金编号",
                              text: _vm.depositInfo.depositNo,
                            },
                          }),
                          _c("col2-item", {
                            attrs: {
                              span: 12,
                              label: "押金名称",
                              text: _vm.depositInfo.depositName,
                            },
                          }),
                          _c("col2-item", {
                            attrs: {
                              span: 12,
                              label: "金额",
                              text: `${_vm.depositInfo.depositMoney}元`,
                            },
                          }),
                          _c("col2-item", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.depositInfo.depositRemark,
                                expression: "depositInfo.depositRemark",
                              },
                            ],
                            attrs: {
                              span: 24,
                              label: "押金备注",
                              text: _vm.depositInfo.depositRemark,
                            },
                          }),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "收费规则" } },
                [
                  !_vm.ruleRealInfo
                    ? _c("col2-item", {
                        attrs: { span: 24, label: "收费规则", text: `无` },
                      })
                    : _c(
                        "div",
                        [
                          _c("col2-item", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.ruleRealInfo.serialNumber,
                                expression: "ruleRealInfo.serialNumber",
                              },
                            ],
                            attrs: {
                              span: 12,
                              label: "实际收费规则编号",
                              text: _vm.ruleRealInfo.serialNumber,
                            },
                          }),
                          _c("col2-item", {
                            attrs: {
                              span: 12,
                              label: "实际收费规则名称",
                              text: _vm.ruleRealInfo.ruleName,
                            },
                          }),
                        ],
                        1
                      ),
                ],
                1
              ),
              _vm.ruleRealInfo
                ? _c(
                    "div",
                    [
                      _c(
                        "col2-block",
                        { attrs: { title: "金额设置" } },
                        [
                          _c("col2-item", {
                            attrs: {
                              span: 24,
                              label: "计算方式",
                              text: `${
                                _vm.chargingTypeMap[
                                  _vm.ruleRealInfo.billingMethod
                                ]
                              }`,
                            },
                          }),
                          _c("col2-item", {
                            attrs: {
                              span: 24,
                              label: "单价",
                              text: _vm.ruleRealInfo.billingPrice,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "col2-block",
                        { attrs: { title: "收费设置" } },
                        [
                          _c("col2-item", {
                            attrs: {
                              span: 24,
                              label: "收费周期",
                              text: `${_vm.ruleRealInfo.chargeCycle}个月`,
                            },
                          }),
                          _c("col2-item", {
                            attrs: {
                              span: 24,
                              label: "首次付款",
                              text: _vm.ruleRealInfo.chargeEndTimeInfo,
                            },
                          }),
                          _c("col2-item", {
                            attrs: {
                              span: 24,
                              label: "首笔账单发放日期",
                              text: _vm.ruleRealInfo.chargeStartTime,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "col2-block",
                        { attrs: { title: "违约金设置" } },
                        [
                          _vm.ruleRealInfo.lateFeeFreeDay
                            ? _c(
                                "div",
                                [
                                  _c("col2-item", {
                                    attrs: {
                                      span: 24,
                                      label: "违约金延期",
                                      text: `${_vm.ruleRealInfo.lateFeeFreeDay}天内免收违约金`,
                                    },
                                  }),
                                  _c("col2-item", {
                                    attrs: {
                                      span: 24,
                                      label: "违约金比率",
                                      text: `${_vm.ruleRealInfo.lateFeeRate}%`,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c("col2-item", {
                                attrs: {
                                  span: 24,
                                  label: "违约金设置",
                                  text: `无`,
                                },
                              }),
                        ],
                        1
                      ),
                      _c(
                        "col2-block",
                        { attrs: { title: "优惠设置" } },
                        [
                          _vm.ruleRealInfo.showDiscountRate
                            ? _c("col2-item", {
                                attrs: {
                                  span: 24,
                                  label: "折扣系数",
                                  text: `${_vm.ruleRealInfo.discountRate}%`,
                                },
                              })
                            : _c("col2-item", {
                                attrs: {
                                  span: 24,
                                  label: "优惠设置",
                                  text: `无`,
                                },
                              }),
                        ],
                        1
                      ),
                      _c(
                        "col2-block",
                        { attrs: { title: "财务设置" } },
                        [
                          _c("col2-item", {
                            attrs: {
                              span: 24,
                              label: "收款法定单位",
                              text: `${_vm.ruleRealInfo.legalUnitName}`,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "col2-block",
                        { attrs: { title: "发放设置" } },
                        [
                          _c("col2-item", {
                            attrs: {
                              span: 24,
                              label: "生成",
                              text: `提前${_vm.ruleRealInfo.billBeforeDay}天自动生成账单`,
                            },
                          }),
                          _c("col2-item", {
                            attrs: {
                              span: 24,
                              label: "发放规则",
                              text: _vm.ruleRealInfo.billGrantFalgString,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "col2-block",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.ruleRealInfo.ruleRemarks,
                              expression: "ruleRealInfo.ruleRemarks",
                            },
                          ],
                          attrs: { title: "收费规则备注" },
                        },
                        [
                          _c("col2-item", {
                            attrs: {
                              span: 24,
                              text: _vm.ruleRealInfo.ruleRemarks,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.detailItem.labelList && _vm.detailItem.labelList.length > 0
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "section-wrapper" }, [
                    _c("span", { staticClass: "tip-wrapper" }, [_vm._v("3")]),
                    _c("span", { staticClass: "title-text" }, [
                      _vm._v("其他信息"),
                    ]),
                  ]),
                  _c(
                    "col2-detail",
                    [
                      _c(
                        "col2-block",
                        _vm._l(
                          _vm.detailItem.labelList,
                          function (item, index) {
                            return _c("col2-item", {
                              key: index,
                              attrs: {
                                span: 24,
                                label: item.labelName,
                                text: item.labelData,
                              },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { attrs: { slot: "footerSlot" }, slot: "footerSlot" },
            [
              _c("v-button", {
                staticClass: "fot-btn",
                attrs: { text: "通过" },
                on: {
                  click: function ($event) {
                    return _vm.passSubmit(0)
                  },
                },
              }),
              _c("v-button", {
                staticClass: "fot-btn",
                attrs: { type: "danger", text: "拒绝" },
                on: { click: _vm.showTextDialog },
              }),
              _c("v-button", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.detailItem.moduleId,
                    expression: "detailItem.moduleId",
                  },
                ],
                staticClass: "fot-btn",
                attrs: { type: "success", text: "预览" },
                on: { click: _vm.goPreview },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialogStyle",
          attrs: { title: "拒绝", width: "500px", visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                { attrs: { "label-width": "120px", "label-suffix": "：" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "拒绝理由",
                        rules: [
                          {
                            required: true,
                            message: "请输入名称",
                            trigger: "blur",
                          },
                        ],
                        prop: "approveRemark",
                      },
                    },
                    [
                      _c("v-textarea", {
                        attrs: { width: 300, maxlength: 100 },
                        model: {
                          value: _vm.approveRemark,
                          callback: function ($$v) {
                            _vm.approveRemark = $$v
                          },
                          expression: "approveRemark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("v-button", {
                attrs: { type: "", text: "取消" },
                on: {
                  click: function ($event) {
                    _vm.dialogVisible = false
                  },
                },
              }),
              _c("v-button", {
                staticClass: "confirm-button",
                attrs: { text: "确定" },
                on: { click: _vm.confirmClick },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }