<template>
  <div class="contractManageDetail-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      @update="update"
    >
      <div class="section-wrapper">
        <span class="title-text">合同信息</span>
      </div>
      <col2-detail>
        <col2-block >
          <col2-item :span="24" label="合同名称" :text="detailItem.contractName" />
          <col2-item :span="24" label="合同编号" :text="detailItem.contractNo" />
          <col2-item :span="24" label="合同起止时间" :text="`${detailItem.startDate || ''} ~ ${detailItem.endDate || ''}`" />
          <col2-item :span="24" label="创建人" :text="`${detailItem.inuser || ''}`" />
          <col2-item :span="24" label="创建时间" :text="`${detailItem.intime || ''}`" />
        </col2-block>
      </col2-detail>
      <div class="section-wrapper">
        <span class="tip-wrapper">1</span>
        <span class="title-text">承租信息</span>
      </div>
      <col2-detail>
        <col2-block >
          <col2-item :span="24" label="关联项目" :text="detailItem.communityName" />
          <col2-item :span="24" label="关联房号" >
           <div v-if="houseList.length > 0" class="list-wrapper">
             <div class="list-row" v-for="(itemList, index) in houseList" :key="index">
               <div class="list-row-item" v-for="(item, itemIndex) in itemList" :key="itemIndex" >
                 <el-tooltip  effect="dark" :content="item.houseName" placement="top">
                   <span class="list-row-text">{{item.houseName}}</span>
                  </el-tooltip>
               </div>
             </div>
           </div>
          </col2-item>
          <col2-item :span="24" label="组织名称" :text="detailItem.orgName" />
          <col2-item :span="24" label="联系人姓名" :text="detailItem.contactUser" />
          <col2-item :span="24" label="联系电话" :text="detailItem.mobileNo" />
          <col2-item :span="24" label="合同附件" v-if="contractFileUrls.length > 0" >
            <div class="list-wrapper">
              <div class="contract-file-wrapper" v-for="(item, index) in contractFileUrls" :key="item.urlPath" >
                <span class="contract-file-text">
                  {{item.name}}
                </span>
                <v-button type="text" text="下载附件" @click="downloadFile(index)"></v-button>
              </div>
            </div>
          </col2-item>
          <col2-item :span="24" label="合同备注" :text="detailItem.remark" v-show="detailItem.remark"/>
        </col2-block>
      </col2-detail>
      <div class="section-wrapper">
        <span class="tip-wrapper">2</span>
        <span class="title-text">收费信息</span>
      </div>
      <col2-detail>
        <col2-block title="押金设置">
          <col2-item v-if="!depositInfo" :span="24" label="押金设置" text="无" />
          <!-- 押金有才显示 -->
          <div v-else>
            <col2-item :span="12" label="押金编号" :text="depositInfo.depositNo" v-show="depositInfo.depositNo"/>
            <col2-item :span="12" label="押金名称" :text="depositInfo.depositName" />
            <col2-item :span="12" label="金额" :text="`${depositInfo.depositMoney}元`" />
            <col2-item :span="24" label="押金备注" :text="depositInfo.depositRemark" v-show="depositInfo.depositRemark"/>
          </div>
        </col2-block>
      </col2-detail>
      <col2-detail>
        <col2-block title="收费规则">
          <col2-item v-if="!ruleRealInfo" :span="24" label="收费规则" :text="`无`" />
           <!-- 收费规则有 才显示下面的内容 -->
          <div v-else>
            <col2-item :span="12" label="实际收费规则编号" :text="ruleRealInfo.serialNumber" v-show="ruleRealInfo.serialNumber"/>
            <col2-item :span="12" label="实际收费规则名称" :text="ruleRealInfo.ruleName" />
          </div>
        </col2-block>
        <!-- 收费规则有 才显示下面的内容 -->
        <div v-if="ruleRealInfo">
          <col2-block title="金额设置">
            <col2-item :span="24" label="计算方式" :text="`${chargingTypeMap[ruleRealInfo.billingMethod]}`" />
            <col2-item :span="24" label="单价" :text="ruleRealInfo.billingPrice" />
          </col2-block>
          <col2-block title="收费设置">
            <col2-item :span="24" label="收费周期" :text="`${ruleRealInfo.chargeCycle}个月`" />
            <col2-item :span="24" label="首次付款" :text="ruleRealInfo.chargeEndTimeInfo" />
            <col2-item :span="24" label="首笔账单发放日期" :text="ruleRealInfo.chargeStartTime" />
          </col2-block>
          <col2-block title="违约金设置">
            <div v-if="ruleRealInfo.lateFeeFreeDay">
              <col2-item :span="24" label="违约金延期" :text="`${ruleRealInfo.lateFeeFreeDay}天内免收违约金`" />
              <col2-item :span="24" label="违约金比率" :text="`${ruleRealInfo.lateFeeRate}%`" />
            </div>
            <col2-item v-else :span="24" label="违约金设置" :text="`无`" />
          </col2-block>
          <col2-block title="优惠设置">
            <col2-item v-if="ruleRealInfo.showDiscountRate"
            :span="24" label="折扣系数" :text="`${ruleRealInfo.discountRate}%`" />
            <col2-item v-else :span="24" label="优惠设置" :text="`无`" />
          </col2-block>
          <col2-block title="财务设置">
            <col2-item :span="24" label="收款法定单位" :text="`${ruleRealInfo.legalUnitName}`" />
          </col2-block>
          <col2-block title="发放设置">
            <col2-item :span="24" label="生成" :text="`提前${ruleRealInfo.billBeforeDay}天自动生成账单`" />
            <col2-item :span="24" label="发放规则" :text="ruleRealInfo.billGrantFalgString" />
          </col2-block>
          <col2-block title="收费规则备注" v-show="ruleRealInfo.ruleRemarks">
            <col2-item :span="24"  :text="ruleRealInfo.ruleRemarks" />
          </col2-block>
        </div>
      </col2-detail>
      <div v-if="detailItem.labelList && detailItem.labelList.length > 0">
         <div class="section-wrapper">
          <span class="tip-wrapper">3</span>
          <span class="title-text">其他信息</span>
        </div>
         <col2-detail>
          <col2-block>
            <col2-item v-for="(item,index) in detailItem.labelList" :key="index" :span="24" :label="item.labelName" :text="item.labelData" />
          </col2-block>
        </col2-detail>
      </div>

      <div slot="footerSlot">
          <v-button class="fot-btn" text="通过" @click="passSubmit(0)"></v-button>
          <v-button class="fot-btn" type="danger" text="拒绝" @click="showTextDialog"></v-button>
          <v-button class="fot-btn" type="success" text="预览" @click="goPreview" v-show="detailItem.moduleId"></v-button>

      </div>
    </form-panel>
    <el-dialog
        title="拒绝"
        width="500px"
        :visible.sync="dialogVisible"
        class="dialogStyle"
      >
      <div>
        <el-form label-width="120px" label-suffix="：">
          <el-form-item label="拒绝理由"  :rules="[{ required: true, message: '请输入名称', trigger: 'blur' }]" prop="approveRemark">
            <v-textarea
              v-model="approveRemark"
              :width="300"
              :maxlength="100"
            ></v-textarea>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <v-button type="" text="取消" @click="dialogVisible = false"></v-button>
        <v-button class="confirm-button"  @click="confirmClick" text="确定"></v-button>
      </div>
      </el-dialog>

  </div>
</template>

<script>
import { queryURL, approveContractURL, downloadPdf } from './api'
import { Col2Detail, Col2Block, Col2Item } from 'components/bussiness'
import {
  chargingTypeMap
} from './map'
import { downloadHelper } from 'common/utils'
import moment from 'moment'
import _ from 'lodash'
import Vue from 'vue'
import { Row, Col, Tooltip } from 'element-ui'
Vue.use(Row)
Vue.use(Col)
Vue.use(Tooltip)

export default {
  name: 'ContractManageDetail',
  components: {
    Col2Detail,
    Col2Block,
    Col2Item
  },
  data () {
    return {
      detailItem: {},
      depositInfo: undefined,
      ruleRealInfo: undefined,
      houseList: [],
      contractFileUrls: [],
      submitConfig: {
        queryUrl: queryURL
      },
      chargingTypeMap: chargingTypeMap,
      approveRemark: '',
      dialogVisible: false,
      pdfUrl: '',
      id: ''
    }
  },
  mounted () {
    const { id } = this.$route.query
    this.id = id
    if (id !== undefined) {
      this.$refs.formPanel.getData({ id })
    }
    this.$setBreadcrumb('查看')
  },
  methods: {
    update (data) {
      // 处理附件
      this.contractFileUrls = data.attachmentInfos || []
      this.detailItem = data
      if (data && data.houseList && data.houseList.length) {
        this.houseList = _.chunk(data.houseList, 4)
      }
      if (data) {
        this.depositInfo = data.depositVo

        let ruleRealVo = data.ruleRealVo
        if (ruleRealVo) {
          if (ruleRealVo.billingStartTime && ruleRealVo.billingStartTime.length && ruleRealVo.chargeEndTime && ruleRealVo.chargeEndTime.length) {
            let chargeEndTime = ruleRealVo.chargeEndTime.replace(/23:59/g, '')
            let earlyDays = moment(ruleRealVo.billingStartTime).diff(moment(chargeEndTime), 'days')
            data.ruleRealVo['chargeEndTimeInfo'] = earlyDays > 0 ? `提前${earlyDays}天付款，首次付款最晚日期 ${ruleRealVo.chargeEndTime}` : `首次付款最晚日期 ${ruleRealVo.chargeEndTime}`
          }
          // 处理显示优惠
          let showDiscountRate = parseFloat(ruleRealVo.discountRate) < 100
          data.ruleRealVo['showDiscountRate'] = showDiscountRate
          data.ruleRealVo['billGrantFalgString'] = ruleRealVo.billGrantFalg === 1 ? '手动发放' : '自动发放'
        }
        this.ruleRealInfo = data.ruleRealVo
      }
    },

    downloadFile (index) {
      // 附件下载
      let item = this.contractFileUrls[index]
      let url = item.urlPath
      let fileName = item.name
      downloadHelper.downloadFile({
        data: url,
        fileName
      })
    },
    async passSubmit (status) {
      let data = {
        contractId: this.$route.query.id,
        status,
        approveRemark: this.approveRemark
      }
      let { status: resStatus } = await this.$axios.put(approveContractURL, data)
      if (resStatus === 100) {
        this.$router.go(-1)
      }
    },
    showTextDialog () {
      this.dialogVisible = true
    },
    confirmClick () {
      if (!this.approveRemark) {
        this.$message.error('请填写拒绝理由')
        return false
      }
      this.passSubmit(2)
    },
    async getPdfUrl () {
      let { data, status } = await this.$axios.get(`${downloadPdf}?contractId=${this.id}`)
      if (status === 100) {
        this.pdfUrl = data
      }
    },
    async goPreview () {
      await this.getPdfUrl()
      if (this.pdfUrl) {
        window.open(this.pdfUrl)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.contractManageDetail-wrapper {
  height: 100%;
  .section-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;

    .tip-wrapper {
      margin-right: 20px;
      background-color: #409EFF;;
      width: 30px;
      height: 30px;
      color: white;
      border-radius:15px;
      text-align: center;
      line-height: 30px;
    }

    .title-text {
      font-size: 18px;
      font-weight: bold;
    }

    .title-small-text {
      font-size: 15px;
    }

  }

  .list-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    .list-row {
      display: flex;
      width: 100%;
      margin-top: 5px;
      margin-bottom: 5px;

      .list-row-item {
        width: 250px;
        margin-right: 20px;
        display: flex;
        align-items: center;
        line-height: 25px;

        .list-row-text {

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .contract-file-wrapper {
      display: flex;
      .contract-file-text {
        margin-right: 50px;
      }
    }
  }
}
.fot-btn {
  margin-right: 20px;
}
.dialogStyle {
  text-align: left;
}
.confirm-button {
  margin-left: 15px;
}
</style>
